import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/user/userSlice';
import paymentReducer from '../slices/payment/paymentSlice';
import moodboardHistoryReducer from '../slices/moodboard/moodboardHistorySlice';

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
  reducer: {
    userReducer,
    paymentReducer,
    moodboardHistory: moodboardHistoryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
