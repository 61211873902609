import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HistoryState,
  MoodboardAction,
} from '../../../redux/store/moodboard/types';

const MAX_HISTORY_LENGTH = 10;

const initialState: HistoryState = {
  past: [],
  future: [],
  canUndo: false,
  canRedo: false,
};

const moodboardHistorySlice = createSlice({
  name: 'moodboardHistory',
  initialState,
  reducers: {
    addAction: (state, action: PayloadAction<MoodboardAction>) => {
      // Quand on ajoute une action, si l'action est une suppression, alors vérifier qu'il n'y a pas d'image ou de texte avec le même id dans les past, si c'est le cas, supprimer les actions dans les past
      if (
        action.payload.type === 'DELETE_IMAGE' ||
        action.payload.type === 'DELETE_PRODUCT_IMAGE' ||
        action.payload.type === 'DELETE_TEXT'
      ) {
        if (action.payload?.payload?.id) {
          const imageId = action.payload.payload.id;
          const imageInPast = state.past.find(
            (action) => action.payload?.id === imageId
          );

          if (imageInPast) {
            state.past = state.past.filter(
              (action) => action.payload?.id !== imageId
            );
          }
        }
      }

      // Si l'action est une modification d'image ou de texte, vérifier que l'image ou le texte est vraiment différent de l'état précédent
      if (
        action.payload.type === 'UPDATE_IMAGE' ||
        action.payload.type === 'UPDATE_TEXT'
      ) {
        if (action.payload.prevState) {
          if (
            JSON.stringify(action.payload.prevState) ===
            JSON.stringify(action.payload.payload?.newAttrs)
          ) {
            return;
          }
        }
      }

      state.past = [
        ...state.past.slice(-MAX_HISTORY_LENGTH + 1),
        action.payload,
      ];
      state.future = [];
      state.canUndo = state.past.length > 0;
      state.canRedo = false;
    },
    undo: (
      state,
      action: PayloadAction<{ updateId?: { oldId: string; newId: string } }>
    ) => {
      if (state.past.length === 0) return;

      const lastAction = state.past[state.past.length - 1];

      state.past = state.past.slice(0, -1);

      // Si on a un updateId, on met à jour l'ID dans l'action
      if (action.payload?.updateId) {
        const updatedAction = {
          ...lastAction,
          payload: {
            ...lastAction.payload,
            id: action.payload.updateId.newId,
          },
        };
        state.future = [updatedAction, ...state.future];
      } else {
        state.future = [lastAction, ...state.future];
      }

      state.canUndo = state.past.length > 0;
      state.canRedo = true;
    },
    redo: (
      state,
      action: PayloadAction<{ updateId?: { oldId: string; newId: string } }>
    ) => {
      if (state.future.length === 0) return;

      const next = state.future[0];
      state.future = state.future.slice(1);

      // Si on a un updateId, on met à jour l'ID dans l'action
      if (action.payload?.updateId) {
        const updatedAction = {
          ...next,
          payload: {
            ...next.payload,
            id: action.payload.updateId.newId,
          },
        };
        state.past = [...state.past, updatedAction];
      } else {
        state.past = [...state.past, next];
      }

      state.canUndo = true;
      state.canRedo = state.future.length > 0;
    },
  },
});

export const { addAction, undo, redo } = moodboardHistorySlice.actions;
export default moodboardHistorySlice.reducer;
